<!--
 * @Descripttion : 
 * @version      : 
 * @Author       : min
 * @Date         : 2020-12-21 12:30:04
 * @LastEditors  : min
 * @LastEditTime : 2021-01-26 17:14:17
-->
<template>
  <div class="ml_banner">
    <a-row>
      <a-col :span="12" v-if="fileList.length > 0">
        <a-carousel :arrows="true" ref="imgCarousel" dots>
          <div v-for="(item, index) in fileList" :key="index">
            <img :src="item.url" alt="" class="ml_banner_img" />
          </div>
        </a-carousel>
        <div style="text-align:center;padding:10px 0;">
          <a-button-group>
            <a-button icon="left" @click="handlePrev">
              上一个
            </a-button>
            <a-button @click="handleNext">
              下一个
              <a-icon type="right" />
            </a-button>
          </a-button-group>
        </div>
      </a-col>
      <a-col :span="12">
        <div class="clearfix ml_upload">
          <a-upload action="" list-type="picture-card" :show-upload-list="false" :fileList="fileList" @preview="handlePreview" :customRequest="customRequest" @change="handleChange">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传Banner
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" centered>
            <img alt="example" style="width: 100%;margin-top:20px;" :src="previewImage" />
          </a-modal>
        </div>
      </a-col>
    </a-row>

    <a-table bordered :data-source="data" :columns="columns" :pagination="pagination" rowKey='id' @change="onShowSizeChange">
      <template slot="bannerUrl" slot-scope="record">
        <img :src="record.bannerUrl" alt="" style="width:120px;">
      </template>
      <template slot="status" slot-scope="record">
        {{ record.status == 1 ? "正常" : "失效" }}
      </template>

      <template slot="action" slot-scope="record">
        <a-dropdown :trigger="['click']" placement="bottomRight">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <template v-if="record.status == 1">
              <a-menu-item @click="clickCloseBanner(record)">
                关闭
              </a-menu-item>
            </template>
            <template v-else>
              <a-menu-item @click="clickOpenBanner(record)">
                打开
              </a-menu-item>
            </template>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>
<script>
import { getBannerList, uploadFile, createBanner, deleteBanner, getAllList } from "../utils/api";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    align: "center"
  },
  {
    title: "Banner图片",
    align: "center",
    scopedSlots: { customRender: "bannerUrl" }
  },
  {
    title: "状态",
    align: "center",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    scopedSlots: {
      customRender: "action"
    }
  }
];

export default {
  name: "bannerMange",
  data() {
    return {
      columns,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      data: [],
      pagination: {
        position: "bottom",
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true
      }
    };
  },
  created() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.pagination.current = 1;
      this.getBannerList();
      this.getAllList();
    },
    onShowSizeChange({ current, pageSize }) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getAllList();
    },
    getAllList() {
      const that = this;
      getAllList({
        current: that.pagination.current,
        count: that.pagination.pageSize
      }).then(res => {
        if (that.pagination.current == 1) {
          that.data = res.data;
        } else {
          that.data = that.data.concat(res.data);
        }
        that.pagination.total = res.count;
      });
    },
    getBannerList() {
      const that = this;
      getBannerList({}).then(res => {
        let _fileList = [];
        res.data.forEach((element, index) => {
          _fileList.push({
            uid: element.id + "",
            name: index + "",
            status: "done",
            url: element.bannerUrl
          });
        });

        that.fileList = _fileList;
      });
    },
    onChange(a, b, c) {
      console.log(a, b, c);
    },
    handlePrev() {
      this.$refs.imgCarousel.prev();
    },
    handleNext() {
      this.$refs.imgCarousel.next();
    },
    handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    customRequest(data) {
      const that = this;
      uploadFile({
        file: data.file,
        Folder: "wxchat"
      }).then(res => {
        let _imgUrl = res.data.imageSiteUrl + "wxchat/pc/" + res.data.imagePath;
        createBanner({
          bannerUrl: _imgUrl
        }).then(() => {
          that.refresh();
        });
      });
    },
    clickCloseBanner(e) {
      // 关闭Banner
      const that = this;
      that.$confirm({
        title: "是否确定关闭改轮播图片？",
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        centered: true,
        onOk() {
          deleteBanner({
            id: e.id,
            status: 0
          }).then(() => {
            that.$message.success("轮播关闭成功！", 1, () => {
              that.refresh();
            });
          });
        },
        onCancel() { }
      });
    },
    clickOpenBanner(e) {
      // 打开Banner
      const that = this;
      that.$confirm({
        title: "是否确定打开改轮播图片？",
        content: "",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        centered: true,
        onOk() {
          deleteBanner({
            id: e.id,
            status: 1
          }).then(() => {
            that.$message.success("轮播打开成功！", 1, () => {
              that.refresh();
            });
          });
        },
        onCancel() { }
      });
    },
    handleChange(e) {
      const that = this;
      if (e.file.status == "removed") {
        // 刪除文件
        that.fileList.forEach(element => {
          if (element.uid == e.file.uid) {
            deleteBanner({
              id: element.uid
            }).then(() => {
              that.refresh();
            });
          }
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    }
  }
};
</script>
<style lang="scss">
.ml_banner {
  padding: 10px 0;
  .ml_banner_img {
    height: 160px;
    width: 400px;
    margin: 0 auto;
  }
  .ml_upload {
    padding: 10px;
  }
  .ant-carousel {
    .slick-slide {
      text-align: center;
      h3 {
        color: #364d79;
      }
    }
  }
}
</style>
